import { createAsyncThunk } from '@reduxjs/toolkit';
import { YieldGeniusState } from '../../../redux-types';
import { getBeefyApi } from '../apis/instances';
import { ApyFeeData } from '../apis/yieldgenius';

export type FetchFeesFulfilledPayload = ApyFeeData;

export const fetchFees = createAsyncThunk<
  FetchFeesFulfilledPayload,
  void,
  { state: YieldGeniusState }
>('fees/fetchFees', async () => {
  const api = getBeefyApi();
  return await api.getFees();
});
