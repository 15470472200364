import React, { memo } from 'react';
import { Container, makeStyles, useMediaQuery } from '@material-ui/core';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconTelegram } from '../../images/socials/telegram.svg';
import { ReactComponent as IconDiscord } from '../../images/socials/discord.svg';
import { ReactComponent as IconTwitter } from '../../images/socials/twitter.svg';
import clsx from 'clsx';
import { useLocation } from 'react-router';

// Re-using header translations, allowing overwrite with footer specific ones
const navLinks = [
  {
    title: ['Footer-Docs', 'Header-Docs'],
    path: 'https://docs.yieldgenius.io/',
  },
  {
    title: 'Footer-Audit',
    path: 'https://github.com/solidproof/projects/tree/main/Yield%20Genius',
  },
];

const socialLinks = [
  {
    title: 'Telegram',
    path: 'https://t.me/YieldGenius',
    Icon: IconTelegram,
  },
  {
    title: 'Discord',
    path: 'https://discord.gg/gUK9uXwuZe',
    Icon: IconDiscord,
  },
  {
    title: 'Twitter',
    path: 'https://twitter.com/YieldGenius_io',
    Icon: IconTwitter,
  },
];

const useStyles = makeStyles(styles);

// Memo: Static footer, no need to re-render when parent does
export const FooterMobile = memo(function () {
  const classes = useStyles();
  const { t } = useTranslation();

  const location = useLocation();
  const isOnDashboard =
    location.pathname.includes('dashboard') || location.pathname.includes('treasury');

  return (
    <div className={clsx(classes.footer, { [classes.userOnDashboard]: isOnDashboard })}>
      <ul className={classes.nav} key={'links'}>
        {navLinks.map(({ title, path }) => (
          <li key={path} className={classes.navItem}>
            <a key={path} href={path} target="_blank" rel="noopener" className={classes.navLink}>
              {t(title)}
            </a>
          </li>
        ))}
      </ul>
      <ul className={classes.nav} key={'social'}>
        {socialLinks.map(({ title, path, Icon }) => (
          <li key={path} className={classes.navItem}>
            <a
              href={path}
              target="_blank"
              rel="noopener"
              className={classes.navLink}
              title={t(title)}
              key={path}
            >
              <Icon />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
});

export const FooterDesktop = memo(function () {
  const classes = useStyles();
  const { t } = useTranslation();

  const location = useLocation();
  const isOnDashboard =
    location.pathname.includes('dashboard') || location.pathname.includes('treasury');

  return (
    <Container maxWidth="lg">
      <div className={clsx(classes.footerDesktop, { [classes.userOnDashboard]: isOnDashboard })}>
        <div className={classes.navDesktop}>
          <div className={classes.rowAlignDesktop}>
            {navLinks.map(({ title, path }) => (
              <a
                key={path}
                href={path}
                target="_blank"
                rel="noopener"
                className={classes.navLinkDesktop}
              >
                <div key={path} className={classes.navItemDesktop}>
                  {t(title)}
                </div>
              </a>
            ))}
          </div>
          <div className={classes.rowAlignDesktop}>
            {socialLinks.map(({ title, path, Icon }) => (
              <div key={path} className={classes.navItem}>
                <a
                  key={path}
                  href={path}
                  target="_blank"
                  rel="noopener"
                  className={classes.navLink}
                  title={t(title)}
                >
                  <Icon />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
});

// This keeps the footer at the bottom of the page when the page is under 100vh
export const WrappedFooter = memo(function WrappedFooter({ children }) {
  const isDesktop = useMediaQuery('(min-width: 800px)');
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperTop}>{children}</div>
      {isDesktop ? <FooterDesktop /> : <FooterMobile />}
    </div>
  );
});
