import { createAsyncThunk } from '@reduxjs/toolkit';
import { YieldGeniusState } from '../../../redux-types';
import { getConfigApi } from '../apis/instances';
import { YieldGeniusConfig, OneInchZapConfig } from '../apis/config-types';

interface FetchAllZapsFulfilledPayload {
  yieldGenius: YieldGeniusConfig[];
  oneInch: OneInchZapConfig[];
}

export const fetchAllZapsAction = createAsyncThunk<
  FetchAllZapsFulfilledPayload,
  void,
  { state: YieldGeniusState }
>('zap/fetchAllZapsAction', async () => {
  const api = getConfigApi();
  const [yieldGenius, oneInch] = await Promise.all([
    api.fetchBeefyZapsConfig(),
    api.fetchOneInchZapsConfig(),
  ]);

  return {
    yieldGenius: yieldGenius,
    oneInch,
  };
});
