import { AbiItem } from 'web3-utils';
import BeefyV2AppMulticallJson from './BeefyV2AppMulticall.json';
import BoostJson from './boost.json';
import BridgeJson from './BridgeAbi.json';
import ERC20Json from './erc20.json';
import MinterJson from './minter.json';
import MulticallJson from './multicall.json';
import SolidlyPairJson from './solidlyPair.json';
import SolidlyFactoryJson from './solidlyFactory.json';
import StrategyJson from './strategy.json';
import UniswapV2PairJson from './uniswapV2Pair.json';
import UniswapV2RouterJson from './uniswapV2Router.json';
import UniswapV2FactoryJson from './uniswapV2Factory.json';
import VaultJson from './vault.json';
import ZapJson from './zap.json';
import BeefyZapOneInchJson from './beefyZapOneInch.json';
import OneInchPriceOracleJson from './oneInchPriceOracle.json';

export const BeefyV2AppMulticallAbi = BeefyV2AppMulticallJson as AbiItem[];
export const BoostAbi = BoostJson as AbiItem[];
export const BridgeAbi = BridgeJson as AbiItem[];
export const ERC20Abi = ERC20Json as AbiItem[];
export const MinterAbi = MinterJson as AbiItem[];
export const MulticallAbi = MulticallJson as AbiItem[];
export const StrategyAbi = StrategyJson as AbiItem[];
export const VaultAbi = VaultJson as AbiItem[];
export const ZapAbi = ZapJson as AbiItem[];
export const BeefyZapOneInchAbi = BeefyZapOneInchJson as AbiItem[];
export const UniswapV2PairAbi = UniswapV2PairJson as AbiItem[];
export const UniswapV2RouterAbi = UniswapV2RouterJson as AbiItem[];
export const UniswapV2FactoryAbi = UniswapV2FactoryJson as AbiItem[];
export const SolidlyPairAbi = SolidlyPairJson as AbiItem[];
export const SolidlyFactoryAbi = SolidlyFactoryJson as AbiItem[];
export const OneInchPriceOracleAbi = OneInchPriceOracleJson as AbiItem[];
