import { createAsyncThunk } from '@reduxjs/toolkit';
import { YieldGeniusState } from '../../../redux-types';
import { YieldGeniusAPIApyBreakdownResponse } from '../apis/yieldgenius';
import { getBeefyApi } from '../apis/instances';

export interface FetchAllApyFulfilledPayload {
  data: YieldGeniusAPIApyBreakdownResponse;
  // reducers need the state (balance)
  state: YieldGeniusState;
}

export const fetchApyAction = createAsyncThunk<
  FetchAllApyFulfilledPayload,
  {},
  { state: YieldGeniusState }
>('prices/fetchApy', async (_, { getState }) => {
  const api = getBeefyApi();
  const prices = await api.getApyBreakdown();
  return { data: prices, state: getState() };
});
