import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  YieldGeniusAPIBuybackResponse,
  YieldGeniusAPILpBreakdownResponse,
  YieldGeniusAPITokenPricesResponse,
} from '../apis/yieldgenius';
import { getBeefyApi } from '../apis/instances';

export type fetchAllPricesPayload = {
  prices: YieldGeniusAPITokenPricesResponse;
  breakdowns: YieldGeniusAPILpBreakdownResponse;
};
export const fetchAllPricesAction = createAsyncThunk<fetchAllPricesPayload, void>(
  'prices/fetchAllPricesAction',
  async () => {
    const api = getBeefyApi();
    const [prices, breakdowns] = await Promise.all([api.getPrices(), api.getLpsBreakdown()]);

    return {
      prices,
      breakdowns,
    };
  }
);

export const fetchBeefyBuybackAction = createAsyncThunk<YieldGeniusAPIBuybackResponse, void>(
  'prices/fetchBeefyBuybackAction',
  async () => {
    const api = getBeefyApi();
    return api.getBuyBack();
  }
);
