import React, { memo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Container,
  Hidden,
  makeStyles,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import { styles } from './styles';
import { NavItem } from './components/NavItem';
import { ReactComponent as VaultsIcon } from '../../images/icons/navigation/vault.svg';
import { ReactComponent as DashboardIcon } from '../../images/icons/navigation/dashboard.svg';
import { ConnectionStatus } from './components/ConnectionStatus';
import { MobileMenu } from './components/MobileMenu';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectShouldInitProposals } from '../../features/data/selectors/data-loader';
import { fetchActiveProposals } from '../../features/data/actions/proposal';

const useStyles = makeStyles(styles);

export const Header = memo(function () {
  const location = useLocation();
  const isOnDashboard =
    location.pathname.includes('dashboard') || location.pathname.includes('treasury');
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 500px)');
  const dispatch = useAppDispatch();
  const shouldLoad = useAppSelector(selectShouldInitProposals);

  useEffect(() => {
    if (shouldLoad) {
      dispatch(fetchActiveProposals());
    }
  }, [dispatch, shouldLoad]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        className={clsx([classes.navHeader, classes.hasPortfolio], {
          [classes.userOnDashboard]: isOnDashboard,
        })}
        position="static"
      >
        <Container maxWidth="lg">
          <div className={isMobile ? classes.mobileContainer : classes.container}>
            <Toolbar className={classes.content} disableGutters={true}>
              <div className={classes.flex}>
                <Link className={classes.yieldgenius} to="/">
                  <img
                    alt="YG"
                    src={
                      isMobile
                        ? require(`../../images/logos/header-logo-notext.png`).default
                        : require(`../../images/logos/header-logo.png`).default
                    }
                  />
                </Link>
                <Hidden mdDown>
                  <NavItem title={'Header-Vaults'} url="/" Icon={VaultsIcon} />
                  <NavItem title={'Header-Dashboard'} url="/dashboard" Icon={DashboardIcon} />
                </Hidden>
              </div>
              <div className={classes.flex}>
                <ConnectionStatus />
                <Hidden lgUp>
                  <MobileMenu />
                </Hidden>
              </div>
            </Toolbar>
          </div>
        </Container>
      </AppBar>
    </Box>
  );
});
