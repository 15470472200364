import { ChainConfig } from '../features/data/apis/config-types';

export const config: Record<ChainConfig['id'], Omit<ChainConfig, 'id'>> = {
  arbitrum: {
    name: 'Arbitrum',
    chainId: 42161,
    rpc: ['https://arb1.arbitrum.io/rpc'],
    explorerUrl: 'https://arbiscan.io',
    multicallAddress: '0x13aD51a6664973EbD0749a7c84939d973F247921',
    appMulticallContractAddress: '0x332f4079E042A5764060E24C72DE765c8Ea1BC95',
    providerName: 'Arbitrum',
    walletSettings: {
      chainId: `0x${parseInt('42161', 10).toString(16)}`,
      chainName: 'Arbitrum One',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: ['https://arb1.arbitrum.io/rpc'],
      blockExplorerUrls: ['https://arbiscan.io/'],
    },
    gas: {
      type: 'eip1559',
      blocks: 100,
      percentile: 0.6,
    },
    stableCoins: [
      'USDC',
      'USDT',
      'MIM',
      'VST',
      'DAI',
      'sarUSDC',
      'sarUSDT',
      'FRAX',
      'USX',
      'MAI',
      'USDD',
      'TUSD',
      'agEUR',
    ],
  },
};

export const chains: string[] = Object.keys(config);
