import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  navLink: {
    display: 'flex',
    ...theme.typography['body-lg'],
    textDecoration: 'none',
    color: theme.palette.text.primary,
    backgroundColor: 'rgba(123, 127, 214, 0.2)',
    border: '1px solid #7B7FD6',
    borderRadius: '6px',
    padding: '5px 35px',
    columnGap: '8px',
    [theme.breakpoints.down('md')]: {
      margin: '5px 10px',
    },
    '& .MuiBadge-root': {
      padding: '0px 12px 0px 0px',
      verticalAlign: 'initial',
      columnGap: '8px',
    },
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: 'rgba(123, 127, 214, 0.5)',
      cursor: 'pointer',
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.disabled,
      '&:hover': {
        color: theme.palette.text.primary,
        '& svg': {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  active: {
    color: theme.palette.text.primary,
    '& svg ': {
      color: theme.palette.text.primary,
    },
  },
  itemMobile: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    columnGap: '8px',
  },
  arrow: {
    height: '12px',
  },
  title: {},
  titleWithBadge: {
    position: 'relative' as const,
    lineHeight: '1',
    display: 'flex',
    alignItems: 'center',
  },
  badge: {},
});
