import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { styles } from './styles';
import YGLogo from '../../images/logos/YGloader.svg';

const useStyles = makeStyles(styles);

export const YGLoader = ({ text }) => {
  const classes = useStyles();
  return (
    <Box textAlign="center">
      <img alt="YG" className={classes.rotateIcon} src={YGLogo} />
      <Box className={classes.text}>{text}</Box>
    </Box>
  );
};
