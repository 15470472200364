import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  navHeader: {
    background: 'transparent',
    boxShadow: 'none',
    '&:hover .MuiListItem-button': {
      background: 'transparent',
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    columnGap: theme.spacing(2),
  },
  hasPortfolio: {
    backgroundColor: theme.palette.background.header,
  },
  userOnDashboard: {
    backgroundColor: theme.palette.background.header,
  },
  container: {
    padding: '25px 3em 25px 3em',
    border: '1px solid #2e2e45',
    boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.13)',
    borderRadius: '1.5em',
    marginTop: '3em',
    backgroundColor: 'rgba(30, 30, 30, 0.2)',
  },
  content: {
    justifyContent: 'space-between',
  },
  yieldgenius: {
    display: 'block',
    marginRight: '2em',
    '& img': {
      height: '50px',
      display: 'block',
    },
  },
  leftMargin: {
    marginLeft: '3em',
  },
  mobileContainer: {
    marginTop: '20px',
  },
});
